import Api, { configFormData } from "@/config/Api";

export default {
  getCreditNotes(options = {}) {
    return Api().get(`/credit-notes`, options);
  },
  getCreditNoteId(creditNoteId) {
    return Api().get(`/credit-notes/${creditNoteId}`);
  },
  addCreditNotes(data) {
    return Api().post("/credit-notes", data);
  },
  updateCreditNoteId(creditNoteid, data) {
    return Api().put(`/credit-notes/${creditNoteid}`, data);
  },
  deleteCreditNoteId(creditNoteid) {
    return Api().delete(`/credit-notes/${creditNoteid}`);
  },
  sendCreditNoteSRI(data) {
    return Api().post(`/sri/signature/credit-note`, data);
  },
  sendCreditNoteMail({ data }) {
    return Api().post(`/credit-notes/send-email`, data, configFormData);
  },
  creditNoteDownload({ id }) {
    return Api().get(`/u/download/credit-note?id=${id}`, {
      responseType: "arraybuffer"
    });
  },
  updateCreditNoteStatusNuled(data) {
    return Api().post(`/credit-notes/nuled`, data);
  }
};
